<template>
  <v-main>
    <v-container
      id="router-container"
      fluid
      :style="{ minHeight: windowMixin_containerMinHeight + 'px'}"
      class="align-self-center"
    >
      <CoreBanner />
      <CoreSnackbar />
      <router-view />
    </v-container>
    <CoreFooter
      class-color="tertiary"
    />
  </v-main>
</template>

<script>
import { windowMixin } from '@/shared/mixins/general'
import { mapActions } from "vuex";

export default {
  name: 'CoreView',

  components: {
    CoreFooter: () => import('./CoreFooter'),
    CoreBanner: () => import('./CoreBanner'),
    CoreSnackbar: () => import('./CoreSnackbar')
  },

  mixins: [windowMixin],

  created () {
    this.$_windowMixin_removeContentClass('pdf-letter-width')
    this.fetchDevicePreviewDeviceSizes()
  },
  methods: {
    ...mapActions('appConfig', ["fetchDevicePreviewDeviceSizes"]),
  }
}
</script>

<style lang="scss">
#router-container.container.pdf-letter-width {
  width: 1024px !important;
}
</style>
