var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-main",
    [
      _c(
        "v-container",
        {
          staticClass: "align-self-center",
          style: { minHeight: _vm.windowMixin_containerMinHeight + "px" },
          attrs: { id: "router-container", fluid: "" }
        },
        [_c("CoreBanner"), _c("CoreSnackbar"), _c("router-view")],
        1
      ),
      _c("CoreFooter", { attrs: { "class-color": "tertiary" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }